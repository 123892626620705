$(document).on('turbolinks:load', function() {
  $('.turbolink-selectpicker').selectpicker();
  $('.dlt-rc').click(function (event) {
    var answer=confirm('Are you sure you want to delete?');
    if(!answer){
      event.preventDefault();
      event.stopPropagation();
    }
  });
  
  $('.new-bill').click(function (event) {
    event.preventDefault();
    $('#new-bill-form #bill_bill_type_id').val(event.target.getAttribute('data-bill-type-id'));
    $('#new-bill-form').submit();
  });

  $('#bill_customer_id').change(function (event) {
    event.preventDefault();
    var customer_id = $(this).val();
    if(customer_id.length) {
      $.get('/bills/get_customer_data', {customer_id: customer_id})
    }
  });

  $('#bill_virtual_group_id').change(function (event) {
    event.preventDefault();
    var group_id = $(this).val();
    if (group_id.length) {
      $.get('/bills/get_group_data', { group_id: group_id })
    }
  });

  $('#bill_payment_method_id').change(function (event) {
    event.preventDefault();
    $.get('/bills/get_payment_method_data', { payment_method_id: $(this).val()})
  });

  $('#bill_remark_id').change(function (event) {
    event.preventDefault();
    $.get('/bills/get_remark_data', {remark_id: $(this).val()})
  });

  $('#compile-bill-remark').click(function (event) {
    event.preventDefault();
    var customerRemark = $('#bill_customer_remarks').val();
    var billRemark = $('#bill_bill_remarks').val();
    $.get('/bills/compile_bill_remark', {bill_remark: billRemark, customer_remark: customerRemark})
  });

  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function() {
    // Toggle the side navigation when window is resized below 480px
    if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
      $("body").addClass("sidebar-toggled");
      $(".sidebar").addClass("toggled");
      $('.sidebar .collapse').collapse('hide');
    };
  });

  $('#add-customer').click(function (event) {
    event.preventDefault();
    var customer_id = $('#customer_id option').filter(':selected').val();
    if (customer_id != "") {
      var id = $(this).data('id');
      $.post('/groups/'+id+'/add_customer', {customer_id: customer_id})
    }
  });
});
