$(document).on('turbolinks:load', function() {
  $('#add-bill-prod').click(function (event) {
    event.preventDefault();
    var product_id = $('#product_id').val();
    var bill_id = event.target.getAttribute('data-bill-id');
    var prod_prefix = $('.bill-prod-row').length;

    if(product_id.length){
      $.ajax({
        url: '/bills/create_bill_product',
        type: 'post',
        data: {
          id: bill_id,
          product_id: product_id,
          prod_prefix: prod_prefix
        }
      });
    }
    else{
      alert('Please select any product!');
    }
  });
});
